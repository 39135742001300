import React from "react";
import { Link } from "react-router-dom";
import aboutIllus from "../images/tutorBooth2.jpg";
import Footer from "./Footer";
import FadeIn from 'react-fade-in';
class About extends React.Component {
  render() {
    return (
        <div
          className="main"
          style={{
            overflowY: this.props.disableScroll ? "hidden" : "scroll",
            position: this.props.disableScroll ? "fixed" : "relative"
          }}
        >
            <div className="rowWithMargin col">
              
              <div className="rowM ">
                <div className="aboutColLeft ">
                    <p className="montserrat bold darkBlueText pageTopTitle">
                        ABOUT CSIL
                    </p>
                    <h2>
                        Serving students and faculty for over 35 years
                    </h2>
                  <p>
                    <br />
                    Welcome to the University of Chicago Computer Science
                    Instructional Laboratory (CSIL)!
                    <br />
                    <br />
                    Since 1985, CSIL has provided students, faculty, and
                    university affiliates with a variety of computing services.
                    <br />
                    <br />
                    Located on the first floor of the John Crerar Library, CSIL
                    offers a wide range of services - from hardware support to
                    computer software to minicourse lessons.
                    <br />
                    <br />
                    Contact us if you have any questions or would like to reserve
                    a lab.
                  </p>
                  <br />
                  <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>
                    <button className="montserrat brightRedButton">CONTACT US</button>
                  </Link>
                </div>

                <div className=" aboutColRight">
                  <img
                    src={aboutIllus}
                    alt="about illustration"
                    className="aboutIllus imgShadow"
                  />
                </div>
              </div>
            </div>
          </div>
    );
  }
}

export default About;
