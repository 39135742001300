import React from "react";
import "./Events.css";
import Footer from "./Footer";
import FadeIn from "react-fade-in";
import events from "./events_data.json";

class Events extends React.Component {
  render() {
    return (
        <div
          className="main"
          style={{
            overflowY: this.props.disableScroll ? "hidden" : "scroll",
            position: this.props.disableScroll ? "fixed" : "relative",
          }}
        >
          <div>
            <div className="rowWithMargin col">
              <h4>
                EVENTS
              </h4>
              <h1>
                Upcoming events hosted by CSIL
              </h1>
            </div>
          </div>
          <div className = "cardGrid">
          {events.map((event, i) => {
                return (
                  <div className="eventsCard">
                    <div className="minicourseCardIn">
                      <div className="minicourseCardImgDiv">
                        <br />
                        <h2>{event.title}</h2>
                        <p className="smText montserrat bold brightRedText">
                          {event.date}
                        </p>
                        <p className="blackText topTextSpace" dangerouslySetInnerHTML={{ __html: event.body }}>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
    );
  }
}

export default Events;
