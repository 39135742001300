import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer>
      {/* Say Hello with link */}
        <div className="footerLeftDiv whiteText">
         <h1>
                <Link
                  to="/contact"
                  onClick= {() => window.scrollTo(0, 0)}
                  className= "footer-title whiteLink"
                >
                  Say hello!
                </Link>{" "}
         </h1>
          {/* Other information */}
          
          {/* Other links */}
          <br />
          <a href="https://computerscience.uchicago.edu/">
            UChicago CS
          </a>
          
          <a
            href="https://maddcenter.uchicago.edu/"
          >
            Media, Arts, Data, and Design (M.A.D.D.)
          </a>
        </div>
        {/* Social media icons */}
        <div className="footerRightDiv">
           <p>
            (773) 702-1082 <br />
            csil@cs.uchicago.edu <br />
            5730 S Ellis Ave<br />
            Chicago, IL 60637
          </p>
          <div className="socialBarRow" style = {{display:'flex', gap: 5 + 'px', width:"mincontent" }}>
            <a href="https://www.facebook.com/uchicagocsil/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className = "socialIcon footer" icon= {faFacebook} />
            </a>
            <a href="https://www.instagram.com/uchicagocsil/?hl=en" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className = "socialIcon footer" icon= {faInstagram} />
            </a>
            <a href="https://twitter.com/UChicagoCSIL" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className = "socialIcon footer" icon= {faXTwitter} />
            </a>
            <a href="mailto: csil@cs.uchicago.edu" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className = "socialIcon footer" icon= {faAt} />
            </a>
          </div>
          
        </div>
    </footer>
  );
}

export default Footer;
