import React from "react";
import minicourseIllus from "../images/tutorSign.jpg";
import { Link, Route } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGitAlt} from '@fortawesome/free-brands-svg-icons';
import {faTerminal} from '@fortawesome/free-solid-svg-icons'
import playerIcon from "../images/player_icon_r.png";
import readIcon from "../images/read_icon_r.png";
import gitpdf from "../minicourses/git.pdf";
import terminalpdf from "../minicourses/terminal.pdf";

class Minicourses extends React.Component {
  render() {
    return (
        <div
          className="main"
          style={{
            overflowY: this.props.disableScroll ? "hidden" : "scroll",
            position: this.props.disableScroll ? "fixed" : "relative",
          }}
        >
          <div className="paddingBottom">

            <div className="rowWithMargin col">
              
              <div className="rowM">
                <div className="aboutColLeft">
                <h4>
                MINICOURSES
              </h4>
              <h1>
                <b>Free</b> student-led minicourse sessions
              </h1>
                  <br />
                  <br />

                  <p className="blackText">
                    CSIL is committed to help you take full advantage of our
                    computing facilities.
                    <br />
                    <br />
                    We invite the university community to our free-of-charge
                    minicourse sessions presented each quarter.
                    <br />
                    <br />
                    Learn about operating systems, programming, and media
                    software in a relaxed environment with plenty of instructor
                    attention.
                    <br />
                    <br />
                    Reservation is not required and paper documentation is
                    provided.
                    <br />
                    <br />
                    Learn more about the minicourse offerings this quarter
                    below.
                  </p>
                </div>

                <div className=" aboutColRight">
                  <img
                    src={minicourseIllus}
                    alt="about illustration"
                    className="aboutIllus imgShadow"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="lterGreyBackground">
            <div className="rowWithMargin textCenter verticalPaddingM">
              <div className="width70percent">
                <h2>
                  Virtual Minicourses
                </h2>


                <p className=" blackText">
                  We've recorded a series of minicourses as video tutorials
                  ready for your use, linked below along with the documentation.
                  Whether you are a faculty member, researcher, or a student, we
                  hope you will find these tutorials helpful.
                  <br />
                  <br />
                  If you are a faculty member and have any new minicourse
                  requests for your course, please don't hesitate to reach out.
                </p>
              </div>
            </div>
          </div>
          <div className="rowWithMargin verticalPaddingM">
            <div className="wrapColumns">
              <div className="minicourseCard">
                <div className="minicourseCardIn">
                  <div className="minicourseCardImgDiv">
                    <h1><FontAwesomeIcon icon={faGitAlt} className = "minicourseHeader"/></h1>
                    <h1>Git</h1>
                    <p className="blackText topTextSpace">
                      Git is a version-control system for tracking changes and
                      coordinating work on collaborative projects. <br />
                      <br />
                      This is very useful if you plan on taking the CS140s.
                    </p>
                    <div className="wrapColumns">


                      <img
                        src={playerIcon}
                        className="cardIcon"
                        alt="player"
                      />
                      <Link to="/gitcourse">
                        <p className="smText montserrat bold brightRedText underNegativeMargin leftTextSpace">
                          INTERACTIVE COURSE
                        </p>
                      </Link>
                      <a
                        href={gitpdf}
                        className="cardLinkDiv cardRow leftCardSpace"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={readIcon}
                          className="cardIcon "
                          alt="player"
                        />

                        <p className=" smText montserrat bold brightRedText underNegativeMargin leftTextSpace">
                          READ NOW
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="minicourseCard"  style = {{opacity:".5"}}>
                <div className="minicourseCardIn">
                  <div className="minicourseCardImgDiv">
                  <h1><FontAwesomeIcon icon={faTerminal} className = "minicourseHeader"/></h1>
                  <h1>Unix</h1>
                    <p className="blackText topTextSpace">
                      Learn how to work from the terminal, specifically in bash
                      and git/svn version control. We will go over: the Linux
                      environment, essential shell commands, text editors,
                      running Python programs from the shell, remote access
                      tools, version control.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Minicourses;
