import React from "react";
import Footer from "./Footer";
import axios from "axios";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { DPickerIn, DPicker } from "./FormStyles";
import DateFnsUtils from "@date-io/date-fns";
import times from "./times.json";
import FadeIn from "react-fade-in";
import { useState, useEffect } from "react";

const CSIL_OPEN = 9.5;
const TIMES = times.splice(CSIL_OPEN * 2 - 3, times.length);
const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#be9a7b",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#800000",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#a1514e",
        borderColor: "#800000",
        "&:hover": {
          backgroundColor: "#a1514e",
          borderColor: "#800000",
        },
      },
      current: {
        color: "#800000",
      },
    },
    MuiButton: {
      label: {
        color: "#800000",
      },
    },
  },
});
const LOCATIONS = {
  "CSIL 1": 1,
  "CSIL 2": 2,
  "CSIL 3": 3,
  "CSIL 4": 4,
  "CSIL 5": 5,
};

class Calendar extends React.Component {
  state = {
    currentDateInput: new Date(),
    currentDate: this.normalizeDate(new Date()),
    labEvents: [],
    rowHeight: 10,
  };

  normalizeTimes(timeStr) {
    var timeArr = timeStr.split("-");
    if (timeArr.length !== 2) {
      return [];
    }
    var startPixel = 0;
    var height = 0;
    var newTimeArr = [0, 0];
    for (var i = 0; i < 2; i++) {
      var timeSplit = timeArr[i].split(":");
      var newNumber = parseFloat(timeSplit[0]);
      newNumber = newNumber + parseFloat(timeSplit[1]) / 60;
      newTimeArr[i] = newNumber;
    }
    startPixel =
      (parseFloat(newTimeArr[0].toFixed(2)) - (CSIL_OPEN - 0.5)) *
        2 *
        this.state.rowHeight +
      1;
    height = parseFloat(
      (newTimeArr[1] - newTimeArr[0]).toFixed(2) * 2 * this.state.rowHeight - 3
      // avoid the overlap
    );
    return [startPixel, height];
  }
  componentDidMount() {
    this.updateWindowDimensions();
    this.interval = setInterval(() => window.location.reload(false), 600000);
    window.addEventListener("resize", this.updateWindowDimensions);
    axios
      .get("https://gcal-reservations.ra.cs.uchicago.edu/")
      .then((response) => {
        var data = response.data.courses;
        console.log("Lab Schedule Data", data);
        var labEvents = {};

        for (var i = 0; i < data.length; i++) {
          var currentClassName = data[i].code;
          for (var a = 0; a < data[i].events.length; a++) {
            var newEvent = {};
            newEvent.title = currentClassName;
            newEvent.location = LOCATIONS?.[data[i].events[a].loc];
            newEvent.type = data[i].events[a].lab;
            newEvent.time = data[i].events[a].time;

            var timeData = this.normalizeTimes(data[i].events[a].time);
            if (timeData.length === 0) {
              continue;
            }

            newEvent.startPixel = timeData[0];
            newEvent.height = timeData[1];

            if (newEvent.location === undefined) {
              continue;
            }
            if (labEvents?.[data[i].events[a].date] === undefined) {
              labEvents[data[i].events[a].date] = [[], [], [], [], []];
            }
            let isNotUniqueEvent = false;
            for (
              var b = 0;
              b <
              labEvents[data[i].events[a].date][newEvent.location - 1].length;
              b++
            ) {
              isNotUniqueEvent =
                labEvents[data[i].events[a].date][newEvent.location - 1][b]
                  .startPixel === newEvent.startPixel;
            }
            if (isNotUniqueEvent) {
              continue;
            }
            labEvents[data[i].events[a].date][newEvent.location - 1].push(
              newEvent
            );
          }
        }
        console.log(labEvents);

        for (var [, value] of Object.entries(labEvents)) {
          for (i = 0; i < value.length; i++) {
            value[i]
              .sort((a, b) => {
                return b.startPixel - a.startPixel;
              })
              .reverse();
          }
        }
        this.setState({ labEvents: labEvents });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  currentDay() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    document.write(today);
  }
  normalizeDate(date) {
    var month = date.toLocaleString("en-us", { month: "short" });
    var day = ("0" + date.getDate()).slice(-2);
    return month + " " + day;
  }
  changeTimeInput(e) {
    this.setState({ currentDateInput: e, currentDate: this.normalizeDate(e) });
  }
  incrementDate(increment) {
    var temp = this.state.currentDateInput;
    temp.setDate(temp.getDate() + increment);
    this.setState({
      currentDateInput: temp,
      currentDate: this.normalizeDate(temp),
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    clearInterval(this.interval);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      rowHeight: (window.innerHeight * 0.86) / TIMES.length,
    });
  };

  setEventColor(event) {
    console.log("Event Date", event);
    var eventEnd = event.time.split("-")[1];

    var rightNow = new Date();
    console.log("date: ", rightNow);
    var currTime = rightNow.getHours() + ":" + rightNow.getMinutes();

    if (currTime > eventEnd) {
      return "#afbec7";
    } else {
      return "#4A5867";
    }
  }

  render() {
    return (
      <FadeIn transitionDuration="1000">
        <div
          className="main"
          style={{
            overflowY: this.props.disableScroll ? "hidden" : "scroll",
            position: this.props.disableScroll ? "fixed" : "relative",
          }}
        >
          <div className="paddingBottom">
            <br /> <br /> <br /> <br />
            <br />
            <div className="rowWithMargin col">
              <p className="montserrat bold darkBlueText pageTopTitle">
                LAB SCHEDULE
              </p>
              <p className="brightRedText pageSubTitle">
                See whats going on in our labs!
              </p>
            </div>
          </div>
          <div className="calendarRow centerHorizontal">
            <DPicker utils={DateFnsUtils}>
              <ThemeProvider theme={materialTheme}>
                <DPickerIn
                  className="dateSelector"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  label="Date"
                  onChange={(e) => this.changeTimeInput(e)}
                  value={this.state.currentDateInput}
                  emptyLabel=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ThemeProvider>
            </DPicker>
          </div>
          <div className="calendarMain2">
            <div className="sideBar">
              {/* rendering the sidebar times */}
              {TIMES.map((time) => (
                <p
                  className="timeText"
                  style={{ height: this.state.rowHeight }}
                  key={time}
                >
                  {time}
                </p>
              ))}
            </div>
            <div className="scheduleMain">
              {/* rendering the sidebar times */}
              {Object.keys(this.state.labEvents).includes(
                this.state.currentDate
              )
                ? Object.keys(LOCATIONS).map((lab, labIndex) => (
                    <div
                      className="dayColumn"
                      style={{width:  this.state.width < 768 ? "40%" : "20%" }}
                      key={labIndex}
                    >
                      <div className="dayColumnHeader">
                        <p className="weekdayTitleText">{lab}</p>
                      </div>
                      {this.state.labEvents[this.state.currentDate][labIndex]
                        .length === 0 ? null : (
                        <div>
                          {this.state.labEvents.length === 0
                            ? null
                            : this.state.labEvents[this.state.currentDate][
                                labIndex
                              ].map((event, eventIndex) => (
                                <div
                                  key={eventIndex}
                                  className="shiftOuterDiv"
                                  style={{
                                    marginTop: this.normalizeTimes(event.time)[0],
                                    background: this.setEventColor(event),
                                    //   : schedule.bgColor,
                                    height: this.normalizeTimes(event.time)[1],
                                    // width: schedule.width
                                  }}
                                >
                                  <div className="shiftInnerDiv">
                                    <b className="shiftTextTitleFullScreen">
                                      {event.title}{" "}
                                    </b>
                                    <p className="shiftTextSubTitleFullScreen">
                                      {event.time}
                                    </p>
                                  </div>
                                </div>
                              ))}
                        </div>
                      )}
                      {TIMES.map((time) => (
                        <div
                          key={time}
                          className="scheduleRowEmpty"
                          style={{ height: this.state.rowHeight }}
                        />
                      ))}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </FadeIn>
    );
  }
}

export default Calendar;
