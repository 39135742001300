import React from "react";
// import { Link } from "react-router-dom";
import cosmos from "../images/staff/cosmos.png";
// import staff_ill from "../images/staff_ill.png";
import staff_members from "./staff_members.json";
import placeholderImage from "../images/staff/placeholder.jpg";
import Footer from "./Footer";
import FadeIn from "react-fade-in";

class Staff extends React.Component {
  state = {
    staff_members: staff_members.staff_members,
  };

  render() {
    return (
      <div
        className="main"
        style={{
          overflowY: this.props.disableScroll ? "hidden" : "scroll",
          position: this.props.disableScroll ? "fixed" : "relative",
        }}
      >
        <div className="colWithMargin">
          <h4>OUR STAFF</h4>
          <h1>Dozens of student tutors at your service</h1>
        </div>
        <div className="rowWithMargin ">
          <div className="topStaffColL">
            <p className="blackText">
              Under the direction of Cosmos Boekell 😎, CSIL is staffed by
              UChicago students who are passionate about technical and user
              support.
              <br />
              <br />
              In addition to supporting the curricular needs of our user base,
              CSIL also acts as a development incubator for our staff to develop
              skillsets that maybe used in the tech industry.
              <br />
              <br />
              If you have any questions or need any assistance, don’t hesitate
              to reach out to Cosmos, a CSIL tutor or stop by our desk.
            </p>
          </div>
          <div className="topStaffColR">
            <div className="staffCosmos">
              <img
                src={cosmos}
                className="staffIllGuyDisplay"
                alt="staff member"
              />
              {/* <img
                  src={fun_cosmos}
                  className= "staffIllGuyDisplay image-hover"
                  alt="staff member"
                /> */}
            </div>
            <p className="blackText leftText noMarginText">
              DIRECTOR - Cosmos Boekell (he/él)
            </p>
            <p className="blackText leftText">cboekell[at]uchicago.edu</p>
          </div>
        </div>
        <div className="rowWithMargin verticalPaddingM">
          <div className="wrapColumns">
            {this.state.staff_members.map((staff_member, i) => {
              let imageSrc;
              try {
                imageSrc = require(`../images/staff/${staff_member.photo}`);
              } catch (err) {
                imageSrc = placeholderImage;
              }
              return (
                <div key={i} className="staffDiv textCenter">
                  <img
                    src={imageSrc}
                    className="staffPicture circleImage image-main"
                    alt={staff_member.name}
                  />
                  <p className="noMarginText staffText">
                    {staff_member.name} {staff_member.pronouns}
                  </p>
                  <p className="noMarginText staffEmailText">
                    {staff_member.email}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Staff;
