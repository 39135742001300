import React from "react";
import Navigation from "./components/Navigation";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Twirl as Hamburger } from "hamburger-react";
// import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Gitcourse from "./components/Interactive Minicourse/Gitcourse";
import TerminalCourse from "./components/Interactive Minicourse/TerminalCourse";
import Software from "./components/Software";
import Hardware from "./components/Hardware";
import Visit from "./components/Visit";
import Staff from "./components/Staff";
import Minicourses from "./components/Minicourses";
import Policies from "./components/Policies";
import Schedule from "./components/LabSchedule";
import Events from "./components/Events";
import Footer from "./components/Footer";

function App() {
  ReactGA.initialize("G-4V51Q52L4Z");
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/software">
            <Software />
          </Route>
          <Route path="/hardware">
            <Hardware />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/visit">
            <Visit />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
          <Route path="/staff">
            <Staff />
          </Route>
          <Route path="/minicourses">
            <Minicourses />
          </Route>
          <Route path="/labschedule">
            <Schedule />
          </Route>
          <Route path="/policies">
            <Policies />
          </Route>
          <Route path="/gitcourse">
            <Gitcourse />
          </Route>
          <Route path="/terminalcourse">
            <TerminalCourse />
          </Route>
        </Switch>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
