import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import FadeIn from 'react-fade-in';
class Policies extends React.Component {
  render() {
    return (
      <div
        className="main"
        style={{
          overflowY: this.props.disableScroll ? "hidden" : "scroll",
          position: this.props.disableScroll ? "fixed" : "relative",
        }}
      >
        <div className="paddingBottom">
          <div className="rowWithMargin col">
            <h4>
              POLICIES
            </h4>
            <h1>
              General Lab Rules
            </h1>
            <div className="rowM ">
              <div className=" ">
                <p className="blackText">
                <br />
                  Due to University guidelines, masks are now optional
                  <br />
                  <br />
                  No eating or drinking in the labs
                  <br />
                  <br />
                  Push in your chair, plug in any cords, and return any borrowed items when you leave
                  <br />
                  <br />
                  Be respectful of others' workspaces
                  <br />

                </p>
                <br />
                <Link to="/contact">
                  <button className="montserrat brightRedButton">CONTACT US</button>
                </Link>
              </div>

              {/* <div className=" aboutColRight">
                <br />
                <br />
                <img
                  src={aboutIllus}
                  alt="about illustration"
                  className="aboutIllus imgShadow"
                />
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="lterGreyBackground">
        <div className="rowWithMargin textCenter verticalPaddingM">
          <div className="width70percent">
            <p className="montserrat bold lightRedText lText">
              WINTER QUARTER HOURS
            </p>

            <p className="blackText">
              CSIL will be providing a help desk this quarter in John Crerar
              Library.
            </p>

            <p className="montserrat blackText">
              Monday – Friday: 9AM–11PM CDT
              <br />
              Saturday: 10AM–6PM CDT
              <br />
              Sunday: 10AM–11PM CDT
            </p>
          </div>
        </div>
      </div> */}
        {/* <div className="rowWithMargin verticalPaddingM">
        <div className="triLWBorder sideBorderR">
          <div className="rowMH">
            <div className="iconDiv">
              <img src={cal} className="icons" alt="calendar icon" />
            </div>
            <div className="iconDivR">
              <p className="montserrat bold regBlueText noMarginText">
                LAB SCHEDULE
              </p>
            </div>
          </div>
          <br />
          <p className="montserrat blackText">
            From RSO meetings to student-led workshops, CSIL hosts a wide
            variety of events. We also assist the CS department faculty by
            maintaining laboratory reservations for their classes. Check out our
            lab schedule above or below.
          </p>
          <Link to="/" className="montserrat bold regBlueText blueArrowLink">
            LAB SCHEDULE ⟶
          </Link>
        </div>
        <div className="triWBorder">
          <div className="rowMH">
            <div className="iconDiv">
              <img src={rocket} className="icons" alt="calendar icon" />
            </div>
            <div className="iconDivR">
              <p className="montserrat bold regBlueText noMarginText">
                MINICOURSES
              </p>
            </div>
          </div>
          <br />
          <p className="montserrat blackText">
            We offer free-of-charge minicourse sessions each quarter. Learn
            about systems, programming, media software, and more in a relaxed
            environment. Reservation is not required and documentation is
            provided.
          </p>
          <Link to="/" className="montserrat bold regBlueText blueArrowLink">
            MINICOURSES ⟶
          </Link>
        </div>
        <div className="triRWBorder sideBorderL">
          <div className="rowMH">
            <div className="iconDiv">
              <img src={write} className="icons" alt="calendar icon" />
            </div>
            <div className="iconDivR">
              <p className="montserrat bold regBlueText noMarginText">
                POLICIES
              </p>
            </div>
          </div>
          <br />
          <p className="montserrat blackText">
            CSIL is open to the public, but we do have many specific policies
            regarding priority of use, user file storage, printing, lost and
            found items, food, smoking, and more. Read more about our specific
            policies above.
          </p>
          <Link to="/" className="montserrat bold regBlueText blueArrowLink">
            POLICIES ⟶
          </Link>
        </div>
      </div> */}
      </div>
    );
  }
}

export default Policies;

