import React, { useState } from "react";
import { Link } from "react-router-dom";
import gitflow from "../../images/branching.png";

const Gitcourse = () => {
    const [gitVersion, setGitVersion] = useState("");
    const [resultgitVersion, setResultGitVersion] = useState("");

    const [gitInit, setGitInit] = useState("");
    const [resultgitInit, setResultGitInit] = useState("");

    const [gitStatus, setGitStatus] = useState("");
    const [resultgitStatus, setResultGitStatus] = useState("");

    const [gitReadMe, setGitReadMe] = useState("");
    const [resultgitReadMe, setResultgitReadMe] = useState("");

    const [gitCommit, setgitCommit] = useState("");
    const [resultGitCommit, setResultGitCommit] = useState("");

    const [gitHelp, setGitHelp] = useState("");
    const [resultGitHelp, setResultGitHelp] = useState("");

    const [gitDiff, setGitDiff] = useState("");
    const [resultGitDiff, setResultGitDiff] = useState("");

    const validateGitVersion = () => {
        if (gitVersion.trim() === "git --version") {
            setResultGitVersion("Correct! 'git --version' shows the version of git you have installed.");
        }
        else {
            setResultGitVersion("Hmm, that doesn't seem right. Try again!");
        }
    }

    const validateGitInit = () => {
        if (gitInit.trim() === "git init") {
            setResultGitInit("Correct! 'git init' initialized empty Git repository in ~/Git Example");
        } else {
            setResultGitInit("Hmm, that doesn't seem right. Try again!");
        }
    };


    const validateGitStatus = () => {
        if (gitStatus.trim() === "git status") {
            setResultGitStatus("Correct! 'git status' shows the working tree status.");
            setGitStatus("")
        } else {
            setResultGitStatus("Hmm, that doesn't seem right. Try again!");
        }
    };
    const validateReadMe = () => {
        if (gitReadMe.trim() === "echo \"This is my first Git repo\" >> README") {
            setResultgitReadMe("Correct! The command lets us create a README file which gives information on the project");
        } else {
            setResultgitReadMe("Hmm, that doesn't seem right. Try again!");
        }
    };
    const validateGitCommit = () => {
        if (gitCommit.trim() === "git commit -m \"Our first commit\"") {
            setResultGitCommit("Correct! The command commits the README file with the message 'Our first commit'");
        } else {
            setResultGitCommit("Hmm, that doesn't seem right. Try again!");
        }
    };
    const validateGitHelp = () => {
        if (gitHelp.trim() === "git pull --help") {
            setResultGitHelp("Correct!");
        } else {
            setResultGitHelp("Hmm, that doesn't seem right. Try again!");
        }
    };
    const validateGitDiff = () => {
        if (gitDiff.trim() === "git diff README") {
            setResultGitDiff("Correct!, git diff README lets you see the changes made");
        } else {
            setResultGitDiff("Hmm, that doesn't seem right. Try again!");
        }
    };

    const terminalStyle = {
        backgroundColor: "black",
        color: "limegreen",
        fontFamily: "'Courier New', Courier, monospace",
        padding: "10px",
        margin: "10px 0",
        border: "none",
        borderRadius: "5px",
        width: "100%",
    };

    const buttonStyle = {
        backgroundColor: "limegreen",
        color: "black",
        border: "1px solid limegreen",
        borderRadius: "5px",
        padding: "5px 10px",
        cursor: "pointer",
        fontFamily: "'Courier New', Courier, monospace",
    };

    const resultStyle = {
        color: "limegreen",
        fontFamily: "'Courier New', Courier, monospace",
    };

    const linuxOutputStyle = {
        backgroundColor: "#282c34",
        color: "#abb2bf",
        fontFamily: "'Courier New', Courier, monospace",
        padding: "10px",
        margin: "10px 0",
        border: "1px solid #abb2bf",
        borderRadius: "5px",
        whiteSpace: "pre-wrap",
    };

    return (
        <div style={{ maxWidth: "600px", margin: "auto", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>Introduction to Git </h1>

            <h2>What is Git and Why do we use it?</h2>
            <p>
                Git	is	open	source	version	control	software:	it	lets	you	"version"	your	own	projects.	This	is	good	because
                it's	hard	to	make	big	changes	all	at	once.	Most	command	line	programs	accept	--version as	a	flag</p>
            <input
                type="text"
                placeholder="Type the command for checking the git version here"
                value={gitVersion}
                onChange={(e) => setGitVersion(e.target.value)}
                style={terminalStyle}
            />
            <button onClick={validateGitVersion} style={buttonStyle}>Submit</button>
            <p style={resultStyle}>{resultgitVersion}</p>
            <p>
                it	will	tell	you	what	version	you	are	running.	I	have	2.5.4.</p>
            <p>Each	period	denotes	more	specific	sub-versions.	1.0	is	initially	when	something	does	what	you	say	it
                does.	Facebook	1.0	might	let	you	send	pictures	to	contacts	but	have	no	filters.</p>
            <p>Consider	writing	your	own	app	or	working	on	a	school	project.	Git	versions	your	code	so	you	can	save
                different	revisions:	maybe	you	want	to	save	the	project	the	first	time	it	compiles;	maybe	it's	finally
                outputting	values,	even	if	the	values	are	wrong. </p>
            <p>Say	you	realize	you've	written	a	specific component	wrong	and	you've	edited	ten	different	files,	moved
                some	around,	and	don't	remember	what	else	you	did.	Git	lets	you	go	back	to	right	before	you	started
                working	on	this.	Tell	Git	to	put	you	back	there	and	everything	returns	to	the	way	it	was	before	you	made
                the	mistake.</p>
            <p>This	scenario	is	merely	a	motivating	example	for	software	versioning.	Git	saves	your	progress	and	marks
                milestones;	when	you	want	to	review	everything	you've	done	since	an	event,	Git	let's	you	know	exactly
                what	happened.
            </p>
            <h2 style={{ margin: "auto" }}>Personal Project</h2>
            <p>Some	of	you	may	know	about	using	GitHub	with	a	group	of	people,	but	we	start	from	the	beginning.	Here
                we	are.</p>
            <h3>Init</h3>
            <p>Setting	up	a	personal	git	project,	we	need	to	create	a	name	and	an	idea	(on	a	compuer,	we	store	it	in	a
                directory).	Let's	create	Git Example/.	We'll	being	working	in	Git Example/,	so	cd inside.	Tell	Git	you
                want	its	help	by	using the init flag in your command</p>
            <input
                type="text"
                placeholder="Type your git command here"
                value={gitInit}
                onChange={(e) => setGitInit(e.target.value)}
                style={terminalStyle}
            />
            <button onClick={validateGitInit} style={buttonStyle}>Submit</button>
            <p style={resultStyle}>{resultgitInit}</p>
            <p>A	repository	(also	known	as	a	"repo")	is	the	top	most	directory	in	the	project	tree.	When	we	write "the	Git
                repository"	we	mean	the	main	directory	of	the	project.	Git	told	us	that	it	is	set	up	in	this	directory	(~/Git
                Example)	and	is	ready	to	work.</p>
            <h3>Status</h3>
            <p>You can check which changes have been staged, which haven't, and which files aren't being tracked by Git by using the status flag.</p>
            <input
                type="text"
                placeholder="Type your git command here"
                value={gitStatus}
                onChange={(e) => setGitStatus(e.target.value)}
                style={terminalStyle}
            />
            <button onClick={validateGitStatus} style={buttonStyle}>Submit</button>


            <p style={resultStyle}>{resultgitStatus}</p>
            <p>Here	is	the	most	usefule	command	in	git,	git status.	It	tells	you	exactly	what	to	do	to	get	out	of	any
                situation	and	also	acts	as	a	sanity	check	to	make	sure	nothing	is	broken.</p>
            <p>The following are the outputs:</p>
            <div style={linuxOutputStyle}>
                <p>On branch master</p>
                <p>Initial commit</p>
                <p>nothing to commit (create/copy files and use "git add" to track)</p>
            </div>
            <p>
                The	second	line	("Initial	commit")	tells	use	Git	doesn't	know	anything	and	waits	for	us	to	instruct	it.
                Luckily,	the	next	line	("nothing	to	commit")	tells	us	what	to	do:	there	is	nothing	in	the	current	directory,
                so	we	should	create	a	file.	Create	a	README	--- which	tells	people	what	your	program	does	and	how	to
                use	it:
                <input
                    type="text"
                    placeholder={`Type 'echo "This is my first Git repo" >> README'`}
                    value={gitReadMe}
                    onChange={(e) => setGitReadMe(e.target.value)}
                    style={terminalStyle}
                />
                <button onClick={validateReadMe} style={buttonStyle}>Submit</button>
                <p style={resultStyle}>{resultgitReadMe}</p>

            </p>
            <p>Now, check the status again</p>
            <input
                type="text"
                placeholder="Type your git command here"
                value={gitStatus}
                onChange={(e) => setGitStatus(e.target.value)}
                style={terminalStyle}
            />
            <button onClick={validateGitStatus} style={buttonStyle}>Submit</button>
            <p style={resultStyle}>{resultgitStatus}</p>

            <div style={linuxOutputStyle}>
                <p>On branch master</p>
                <p>Initial commit</p>
                <p>
                    Untracked files:
                    {`(use "git add <file>..." to include in what will be committed)`}
                </p>
                <p>README</p>
                <p>
                    {`nothing added to commit but untracked files present (use "git add" to track)`}
                </p>
            </div>
            <p>
                Git	knows	what	you've	done:	this	is	called	"tracking"	your	files.	Git	is	aware	of	README	but	is	not
                tracking	it.
            </p>
            <h3>Add and Commit</h3>
            <br></br>
            <p>Now, we need to tell Git to track the README file. We do this by using the add flag.</p>
            <p>After adding it, we can check the status again using the git status command, which would give us the following output</p>
            <div style={linuxOutputStyle}>
                <p>On branch master</p>
                <p>Initial commit</p>
                <p>
                    Changes to be committed:
                    {`(use "git rm --cached <file>..." to unstage)`}
                </p>
                <p>new file: README</p>
            </div>
            <p> Now	Git	is	tracking	the	README	file	and	our	changes	are	"staged	for	commit".	This	means	the	changes
                aren't	officially	saved	yet,	but	we	are	ready	to	"commit"	the	changes:</p>
            <p>We commit the changes by using the commit flag. This will save the changes to the repository.</p>
            <p>here an example of a git commit!</p>
            <div style={linuxOutputStyle}>
                <p>$ git commit -m "This is a commit!"</p>
            </div>
            <p>the -m flag allows you to input a commit message</p>
            <p>Can you commit your changes with the commit message "Our first commit"</p>
            <input
                type="text"
                placeholder="Type your git command here"
                value={gitCommit}
                onChange={(e) => setgitCommit(e.target.value)}
                style={terminalStyle}
            />
            <button onClick={validateGitCommit} style={buttonStyle}>Submit</button>
            <p style={resultStyle}>{resultGitCommit}</p>

            <p>
                We	made	our	first	commit,	by	telling	Git	that	we	have	changes	we	want	to	track	and	to	mark	our
                repository	as	it	is.	Anytime	you	commit	changes,	you	include	a	message	to	help	you	differentiate	your commits.	Good	messages	are	useful:	in	the	earlier	scenario, remembering	to	go	back	to	a	commit	labelled
                "Part	1	working"	is	easier	than	going	back	to	a	commit	labelled	"ef154ffa".
            </p>
            <p>
                If	you	run git commit
                with	no	-m flag,	Git	opens	your	preferred	text	editor	for	writing	a	longer	commit	message.
                The	output	from	git commit tells	you	how	things	change	due	to	that	commit:	the	number	of	files
                changed,	files	that	were	created,	and	other	information	(when	bigger	changes	are	made).
            </p>

            <p> Now if we run git status again, we get the following output</p>

            <div style={linuxOutputStyle}>
                <p>On branch master</p>
                <p>nothing to commit, working directory clean</p>
            </div>

            <p>
                Git	tells	us	nothing	has	changed	since	the	last	commit.
                This	is	the	lifecycle	of	most	commits:	make	a	change,	use	git add to	track	it,	and	use	git commit to
                record	it.	Knowing	this	is	enough	to	start	using	Git.
                Using	git status frequently	is	a	good	habit	for	beginners,	especially	as	your	initial	response	to	problems
                when	using	Git.	Running	it	will	often	tell	you	what	you	need	to	do	to	remedy	the	situation.
            </p>
            <br></br>
            <h2 style={{ margin: "auto" }}>Remote Project</h2>
            <p>Say	you	return	home	and	want	to	continue	working	on	the	project	with	your	personal	computer.	Git
                easily	transfers	the	project's	files	between	computers.</p>
            <h3> Remote Repositories</h3>

            <p>
                If	we	are	all	working	on	a	project	(versioned	with	Git)	and	I	give	you	an	erroneous	file,	then	how	do	I
                quickly	get	a	patched	file	out	to	you?
                Committing	the	patched	file	tells	Git	how	to	make	the	changes	to	patch	the	file	and	you	can	use	a	remote
                repository	to	patch	your	personal	repository.	A	repository	is	a	directory	with	Git,	so	a	remote	repository
                is	a	remote	directory	with	Git.</p>
            <p>
                Why	remote	repositories?
            </p>
            <p>
                1. You	are	interested	in	my	changes	and	need	Git	to	know	about	the	remote	repository	on	my
                computer,
            </p>
            <p>
                OR
            </p>
            <p>
                2. You	want	to	access	your	own	projects	from	multiple	machines.	I	work	on	my	desktop	at	home	and
                use	my	laptop	everywhere	else.
            </p>
            <p>
                In	the	second	scenario,	I	use	remote	repositories	to	refer	to	the	same	project	from	different	computers.
                Remote	repositories	can	exist	anywhere,	but	generally	you	use	services	like	GitHub	to	host	them.	GitHub
                stores	your	code	and	Git's	versioning	of	it.

            </p>

            <h3> Remote Repositories</h3>
            <p> To	access	my	git	repository	from	your	computer,	run</p>
            <div style={linuxOutputStyle}>
                <p>$ git clone &lt;url&gt;</p>
            </div>

            <p> Where	&lt;url&gt; is	my	project's	GitHub	URL.	You	now	have	replica	of	my	repository.	If	I	continue	to	update
                files	on	the	remote	repository,	run</p>

            <div style={linuxOutputStyle}>
                <p>$ git pull</p>
            </div>
            <p>to	retriev the	updates.</p>


            <p>

                The	output	of	git pull looks	like	the	output	of	git commit:
            </p>
            <p>
                1. the	output's	first	section	regards	connecting	to	the	remote	repository	and	retrieving	its	files;
            </p>
            <p>
                2. the	second	regards	statistics	describing	the	file	changes.
            </p>

            <p>
                This	applied	my	commit	to	update	your	files.	Whenever	the	remote	repository	changes,	just	use	git pull
                to	update	your	repository.
            </p>
            <p>
                The	last	command	to	know	before	interacting	with	remote	repositories	is	git push,	which	performs	the
                opposite	action	of	git pull.	git push applies	your	commits	to	the	remote	repository.
            </p>
            <p>
                For	example,	if	I	wanted	everyone	taking	this	minicourse	to	put	their	name	in	the	bottom	of	the	README,
                you	could	each	write	your	own	name	in	the	README,	add	and	commit	the	changes,	then	push	to	the
                remote	repository:
            </p>

            <div style={linuxOutputStyle}>
                <p>$ echo "Basheera Ali" >> README</p>
                <p>$ git add README</p>
                <p>$ git commit -m "Added name to README"</p>
                <p>$ git push</p>
            </div>
            <p>Let's	review	what	these	commands	accomplished:</p>
            <p>1. Append	our	name	to	the	README,</p>
            <p>2. Use	git add for	Git	to	track	the	changes,</p>
            <p>3. Use	git commit for	Git	to	save	the	changes,</p>
            <p>4. Use	git push to	update	the	remote	repository	with	the	changes.</p>
            <p>5. The	purpose	of	GitHub	is	to	allow	you	to	push	and	pull	your	code	from	anywhere,	allowing	you	to	work
                on	your	codebase	anywhere.</p>
            <h3> Miscellaneous</h3>
            <p>
                You	now	know	elementary	Git;	Git	provides	many	commands	and	utilities,	but	we	don't	have	time	to
                cover	them	all.	Brief	tutorials	of	using	other commands	follow,	to	study	at	your	leisure.
            </p>
            <h3> Branching</h3>

            <img src={gitflow} />
            <p>
                Git	works	like	a	tree:	we	start	on	the	main	branch,	always	called	"master".	What	about	other	branches?

            </p>
            <p> If	you	want	your	development	work	to	be	separate	from	your	production	work,	you	can	create	a	branch:</p>
            <div style={linuxOutputStyle}>
                <p>$ git branch development master</p>
            </div>
            <p>
                This	creates	the	branch	"development",	which	begins	as	a	copy	of	"master".	To	see	an	enumeration	of	our
                branches,	run
            </p>

            <div style={linuxOutputStyle}>
                <p>$ echo "I teach suffering, its origin, cessation and path." >> README </p>
            </div>
            <p>Now	switch	branches,	from	"master"	to	"development":</p>

            <div style={linuxOutputStyle}>
                <p>$ git checkout development</p>
                <p>$ git branch</p>
                <p>master</p>
                <p>* development</p>
            </div>

            <p> When	you	examine	README,	notice	that	the	line	you	just	added	is	not	there.	If	you	were	to	now	make
                changes	to	the	README	and	add	and	commit	them,	then	you	can	switch	back	to	"master"	and	the	changes
                will	not	carry	over.</p>
            <p> Branches	are	powerful:</p>
            <p>1. having	a	development	branch	permits	testing	code	before	moving	it	to	production;</p>
            <p>2. groups	working	on	the	same	project	can	use	personal	branches	to	work	without	affecting	each	other,
                and	push	and	pull	from	the	master	branch	when	ready.</p>
            <p>To	delete	a	branch,	run</p>
            <div style={linuxOutputStyle}>
                <p>$ git branch -d development</p>
            </div>

            <h2>Merging</h2>
            <p>Revisiting	the	prior	scenario,	let's	create	a	branch	to	add	our	name	to	the	README	and	then	merge	the
                changes	into	the	master	branch:</p>
            <div style={linuxOutputStyle}>
                <p>$  git checkout -b branch2</p>
                <p>$ git checkout add-name</p>
                <p>$ echo "this is test2" >> README</p>
                <p>$ git add README</p>
                <p>$ git commit -m "Added name to README"</p>
                <p>$ git checkout master</p>
                <p>$ git merge branch2</p>
            </div>

            <p>The	new	command	is	git merge Guo_Jingjing.	Running	it	from	the	master	branch,	it	asks	Git	to	apply
                all	tracked	changes	on	the	branch	"Guo_Jingjing"	to	current	branch	("master").	When	merging,	you	must
                give	a	message	(similar	to	committing);	the	default	message	is	sufficiently	descriptive	for	us.</p>

            <p>   In	the	simplest	circumstances,	Git	merges	your	changes	without	your	help.	But	if	the	file	has	changed	on
                multiple	branches,	Git	needs	your	intervention.	On	the	master	branch,	Git	may	know	the	last	line	of
                README	as	"this is branch2"	,	but	an	attempted	merge,	the	name	is	not	there.	Git	acts	conservatively:	when
                unable	to	automatically	resolve	conflicts,	it	delegates	to	you.	This	type	of	conflict	is	known	as	a	"merge
                conflict".	Looking	at	the	problem	file,	we	see</p>

            <div style={linuxOutputStyle}>
                <p>&lt;&lt;&lt;&lt;&lt; Head </p>

                <p>this is branch2</p>
                <p>======</p>
                <p>Basheera Ali</p>
                <p>{'>>>>>'} Other Branch</p>
            </div>
            <p>This	asserts	that	on	"master",	the	README	ends	with	"this is branch2",	while	on	the	branch	we	are	merging	into
                master,	the	README	ends	with	"Basheera	Ali"</p>

            <p>Because	we	want	both	names,	simply	delete	the	lines	Git	added,	leaving</p>
            <div style={linuxOutputStyle}>
                <p>this is branch2</p>
                <p>Basheera Ali</p>
            </div>

            <p>Now	that	you	resolved	the	merge	conflict,	run</p>
            <div style={linuxOutputStyle}>
                <p>$ git add README</p>
                <p>$ git commit -m "Resolved merge conflict with names"</p>
            </div>
            <p>to	finish	the	merge.</p>
            <br>
            </br>
            <h2>Utilities</h2>
            <p>The	final	two	commands	we	will	cover	are	git log and	git diff.	Remember	that	you	can	always	learn
                more	about	a	command	(e.g.	"pull")	by using the --help flag</p>

            <p>Can you write out the command to learn more about git pull</p>
            <input
                type="text"
                placeholder="Type your git command here"
                value={gitHelp}
                onChange={(e) => setGitHelp(e.target.value)}
                style={terminalStyle}
            />
            <button onClick={validateGitHelp} style={buttonStyle}>Submit</button>
            <p style={resultStyle}>{resultGitHelp}</p>

            <h3>Log</h3>
            <p>Use	git log to	review	your	commit	history,	which	outputs	details	of	recent	commits:</p>
            <div style={linuxOutputStyle}>
                <p>$ git log</p>
            </div>
            <div style={linuxOutputStyle}>
                <p>commit 72c1cbacfe6639a4dabf5c67ccd2ac059c5b210b</p>
                <p>Author: DAVID CHEN {"<"}myemail@ example.com{">"}</p>
                <p>Date: Fri Mar 25 10:40:43 2016 -0500</p>
                <p>// and many more of these blocks</p>
            </div>

            <p>
                For	each	clause,<br></br>
                <br></br>
                • the	first	line	contains	the	commit's	checksum	(essentially	a	unique	number),<br></br>
                • the	second	line	contains	the	contact	information	of	whomever	made	the	commit,<br></br>
                • the	third	line	contains	a	timestamp	for	the	commit,	and<br></br>
                • the	succeeding	lines	contain	the	commit	message.<br></br>
                <br></br>
                Git	takes	over	your	shell	when	running	git log:	scroll	with	the	arrow	keys	and	type	q	to	quit
            </p>
            <h3>Diff</h3>
            <p>git diff shows	the	changes	made	to	a	file	since	its	last	commit:</p>
            <p>Can you write out the command to show the chages made to the README file</p>
            <input
                type="text"
                placeholder="Type your git command here"
                value={gitDiff}
                onChange={(e) => setGitDiff(e.target.value)}
                style={terminalStyle}
            />
            <button onClick={validateGitDiff} style={buttonStyle}>Submit</button>
            <p style={resultStyle}>{resultGitDiff}</p>

            <div style={linuxOutputStyle}>
                <p>$ git diff README</p>
                <p>diff --git a/README b/README</p>
                <p>index d9b8fce..03ddf13 100644</p>
                <p>--- a/README</p>
                <p>+++ b/README</p>
                <p>@@ -5,11 +5,12 @@ author: <br></br>
                    date: 2016-03-16 <br>
                    </br>
                    ...
                </p>
                <p>-# What is git and why do we use it? <br></br>
                    +# What is Git and Why do we use it?
                </p>
            </div>
            <p>The	initial	clause	holds	useful	information,	but	it	is	crucial	to	understand	the	second	clause	(beginning
                after	the	ellipsis):	lines	starting	with	- are	from	the	former	version,	and	lines	starting	with	+ are	from	the
                current	version
            </p>
            <p>Without	additional	arguments,	git diff compares	the	current	and	former	versions	of	the	file.
                Additionally,	you	can	define	your	comparison	over	branches	or	commits:
            </p>

            <div style={linuxOutputStyle}>
                <p>$ git diff development README</p>
            </div>
            <p>compares	against	the	branch	"development",	and</p>
            <div style={linuxOutputStyle}>
                <p>$ git diff 55bc README</p>
            </div>
            <p>compares	against	the	"Trying	merge	conflict	again"	commit.	You	can	specify	a	commit	with	(at	least)	the
                first	four	characters	of	the	commit	ID.	If	two	commits	agree	on	the	first	four	characters,	supply	enough	to
                differentiate	your	commit.
            </p>

            <h3>Gitignore</h3>
            <p>
                Frequently,	projects	contain	files	undesirable	to	version: <br></br>
                <br></br>
                • object	files	created	by	a	compiler, or <br></br>
                • sensitive	information,	like	a	list	of	passwords. <br></br>
                <br></br>
                A	.gitignore file	deliberately	prevents	files	from	being	versioned.	It	contains	a	list	of	file	patterns	to
                ignore.	For	example,	this	.gitignore
            </p>
            <div style={linuxOutputStyle}>
                <p>*.o </p>
                <p>passwords.txt</p>
            </div>
            <p>prevents	Git	from	tracking	any	file	ending	with	.o and	the	file	named	passwords.txt.</p>
            <p>You	can	create	your	own	.gitignore,	but	first	research before you start ignoring files!</p>

            <h3>Conclusion</h3>
            <p>Never	forget,	Google	can	be	your	best	friend.</p>

            <p>
                Git	Documentation <br></br>
                https://git-scm.com/doc
                <br>
                </br>
                <br>
                </br>
                GitHub	Lessons <br></br>
                https://try.github.io/levels/1/challenges/1
                <br></br>
                <br></br>
                Git	Immersion	- Walkthrough <br></br>
                http://gitimmersion.com/
                <br></br>
                <br></br>
                Codecademy	- Learn	Git <br></br>
                https://www.codecademy.com/learn/learn-git
                <br></br>
                <br></br>
                Into	to	Git <br>
                </br>
                http://git-scm.com/docs/gittutorial
                <br>
                </br>
                <br></br>
                Another	Guide <br></br>
                http://rogerdudler.github.io/git-guide/
            </p>
            <p>Thank you!</p>
        </div >



    );
};

export default Gitcourse;
