import React from "react";
import { Link } from "react-router-dom";

class TerminalCourse extends React.Component {
    render() {
        return <div><p> Coming Soon </p> </div>
    }
}

export default TerminalCourse;
