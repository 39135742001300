import React from "react";
import outerCsil from "../images/outerCsil.jpg";
import Footer from "./Footer";
import FadeIn from 'react-fade-in';
class Visit extends React.Component {
  render() {
    return (
      <div
        className="main"
        style={{
          overflowY: this.props.disableScroll ? "hidden" : "scroll",
          position: this.props.disableScroll ? "fixed" : "relative",
        }}
      >
        <div className="paddingBottom">
          <br /> <br /> <br /> <br />
          <br />
          <div className="rowWithMargin col">
            <p className="montserrat bold darkBlueText pageTopTitle">
              VISIT US
            </p>
            <p className="brightRedText pageSubTitle">Come see us!</p>
            <div>
              <div className="rowM">
                <div className="aboutColLeft ">
                  <p className="">
                    ​​We’re on the ground floor of the John Crerar Library (see
                    map below)! When entering from the main entrance, take a
                    right and enter the MADD center through the large glass
                    wall. Head over to the west side of the MADD center to which
                    you will find the CSIL Labs! The CSIL tutor station is
                    located between CSIL Lab 3 and 4 which is roughly at the
                    center of the west wall of the MADD center. If you can't
                    find us, ask a friendly MAAD attendant in a blue lab coat!
                  </p>
                </div>

                <div className="aboutColRight centerColVert">
                  <div className="visitImageDiv">
                    <img
                      src={outerCsil}
                      alt="csil glass"
                      className="visitImage imgShadow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="colWithMargin verticalPaddingM centerVertical">
            <br />
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2974.7535830949623!2d-87.60504268442789!3d41.790523979229384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e293c21dac439%3A0xcf45cf3ba1a46e95!2sJohn%20Crerar%20Library!5e0!3m2!1sen!2sus!4v1627858037611!5m2!1sen!2sus"
              width="60%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="map"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Visit;
