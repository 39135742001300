import React from "react";
import softwares from "./softwares.json";
import "./Software.css"
import Footer from "./Footer";
import FadeIn from 'react-fade-in';
class Software extends React.Component {
  state = {
    query: "",
    softwares: softwares.softwares,
  };

  handleChange = (e) => {
    this.setState({ query: e.target.value }, () => {
      for (let i = 0; i < this.state.softwares.length; i++) {
        if (
          e.target.value.trim() !== "" &&
          this.state.softwares[i]
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          this.setState({ ["s" + i.toString() + "IsActive"]: true });
        } else {
          this.setState({ ["s" + i.toString() + "IsActive"]: false });
        }
      }
    });
  };
  render() {
    return (
      <div className="main" style={{ overflowY: this.props.disableScroll ? "hidden" : "scroll", position: this.props.disableScroll ? "fixed" : "relative"}}>
        <div className="rowWithMargin col" style = {{paddingBottom:"25px"}}>
          <h4>
            AVAILABLE SOFTWARE
          </h4>

          <h1>
            Search our software
          </h1>
          <input
            placeholder="Search our resources"
            className="searchInput"
            onChange={this.handleChange}
            value={this.state.query}
          ></input>
          <br></br>
          <div className="softwareColumns">
              {this.state.softwares.map((software, softwareIndex) => {
                return softwareIndex < this.state.softwares.length / 1 ? (
                  <p className="softwareListText">
                    <span
                      style={
                        this.state.query === "" ?
                        {
                        color: "black",
                      }
                      : this.state[
                        "s" + softwareIndex.toString() + "IsActive"
                      ]
                        ? { background:  "yellow"}
                          : {color:"grey"}
                    }
                    >
                      {software}
                    </span>
                  </p>
                ) : null;
              })}
            </div>
          </div>
        </div>
    );
  }
}

export default Software;
